import React from "react"
// import { Link } from "gatsby"
import FAQ from "../../utils"
import Layout from "../components/layout"
import SEO from "../components/seo"
import questions from "../../manualFaq.json"

function Manualtesting() {
 
return (
  <Layout>
    <SEO
      title="Manual Testing Company in Delhi NCR, India, USA & UK"
      description="Manual Testing is one of the core services offered by D2i. D2i is one of the leading company in India specializing in Manual Testing."
    />
    <div className="text-justify">
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h1>Manual Testing Company</h1>
            </div>

            <p>
              Manual testing is a software testing process in which test cases
              are executed manually without using any automated tool. All test
              cases executed by the tester manually according to the end user's
              perspective. It ensures whether the application is working, as
              mentioned in the requirement document or not. Test cases are
              planned and implemented to complete almost 100 percent of the
              software application. Test case reports are also generated
              manually.
            </p>

            <p>
              Manual Testing is one of the most fundamental testing processes as
              it can find both visible and hidden defects of the software. The
              difference between expected output and actual output given by the
              software, is defined as a defect. The developer fixed the defects
              and handed it to the tester for retesting.
            </p>

            <p>
              Manual testing is mandatory for every newly developed software
              before automated testing. Automation testing does not replicate
              the minds of humans, and since humans are the ones using the
              software, manual testing is a necessary part of building software
              application. This testing requires great efforts and time, but it
              gives the surety of bug-free software. Manual Testing requires
              knowledge of manual testing techniques but not of any automated
              testing tool.
            </p>

            <p>
              You can use our independent functional testing services which will
              provide you feedback from users perspective, our test engineers
              find issues by putting themselves in end users shoes.&nbsp;
            </p>

            <p className="mb-2">
              There are various methods used for manual testing. Each technique
              is used according to its testing criteria. Methods of manual
              testing are given below:
              
            </p>
            
            <ul className="mb-4">
                <li>White Box Testing</li>
                <li>Black Box Testing</li>
                <li>Grey Box Testing</li>
              </ul>

            <h2 className="cloudhead h3 mb-4">
              Types of manual testing D2i Technology perform:
            </h2>
            <h3 className="cloudhead h4">Smoke Testing</h3>
            <p>
              Smoke Testing is a software testing technique performed post
              software build to verify that the critical functionalities of the
              software are working fine. It is executed before any detailed
              functional or regression tests are executed.
            </p>

            <h3 className="cloudhead h4">Sanity Testing</h3>
            <p>
              Sanity testing is a kind of Software Testing performed after
              receiving a software build, with minor changes in code, or
              functionality, to ascertain that the bugs have been fixed and no
              further issues are introduced due to these changes. The goal is to
              determine that the proposed functionality works roughly as
              expected.
            </p>

            <h3 className="cloudhead h4">Regression Testing</h3>
            <p>
              Regression Testing is defined as a type of software testing to
              confirm that a recent program or code change has not adversely
              affected existing features.
            </p>

            <h3 className="cloudhead h4">Retesting</h3>
            <p>
              Retesting means testing the functionality or bug again to ensure
              the code is fixed. If it is not fixed, defects need to be
              re-opened. If fixed, the defect is closed.
            </p>

            <h3 className="cloudhead h4">Functional Testing</h3>
            <p>
              Your website is the face of your business for online customers;
              making sure that your website or web application has flawless
              functionality is the least your customer is expecting. Our team
              can evaluate and help you deliver a perfect web application with
              smooth functionality. We have functional testing experts to test
              the web applications on various parameters to ensure that your
              end-user has great experience and they are able to use your
              application without any issues. We test your web application on
              different combinations of operating system, browser, devices etc.
            </p>
          </div>
        </div>
        <div className="FAQ">{FAQ(questions)}</div>
      </div>
    </div>
  </Layout>
)
}

export default Manualtesting
